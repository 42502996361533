var RDF, altura, availClass, bigmap, bigmapzoom, bigmarker, buildRouteMap, calculateDistance, callablenum, callablenumBuy, changeSlideImg, changetimer, chartsloaded, closeGalleryModal, counter, createBigMap, createHQMap, createOrderMap, currentPos, eachmargin, fecharEncomendas, fecharMenu, firstCateg, firstStore, height, hintsLoaded, i, map, mapoffset, marker, myLatlng, nexttext, offset, optionsCharts, otherSelector, previouscity, productsize, randWhere, randleft, randtop, resizeProducts, selector, setOffset, showCateg, size, sliderNumber, sliderTiming, spotCounter, styles, subselector, tel, telcontainsplus, tele, thecolor, triangleMultiplier, url, where, width;

map = '';

bigmap = '';

myLatlng = '';

marker = '';

bigmarker = '';

currentPos = '';

styles = [
  {
    'featureType': 'landscape',
    'stylers': [
      {
        'saturation': -100
      }, {
        'lightness': 100
      }, {
        'visibility': 'on'
      }
    ]
  }, {
    'featureType': 'poi',
    'stylers': [
      {
        'saturation': -100
      }, {
        'lightness': 51
      }, {
        'visibility': 'simplified'
      }
    ]
  }, {
    'featureType': 'road.highway',
    'elementType': 'geometry.fill',
    'stylers': [
      {
        'color': '#dddddd'
      }, {
        'lightness': 50
      }, {
        'weight': 2.2
      }
    ]
  }, {
    'featureType': 'road.highway',
    'elementType': 'geometry.stroke',
    'stylers': [
      {
        'color': '#aaaaaa'
      }, {
        'lightness': 50
      }, {
        'weight': 0
      }, {
        'visibility': 'off'
      }
    ]
  }, {
    'featureType': 'road.arterial',
    'elementType': 'geometry.fill',
    'stylers': [
      {
        'color': '#dddddd'
      }, {
        'lightness': 50
      }, {
        'weight': 1
      }
    ]
  }, {
    'featureType': 'road.arterial',
    'elementType': 'geometry.stroke',
    'stylers': [
      {
        'color': '#aaaaaa'
      }, {
        'lightness': 50
      }, {
        'weight': 0
      }, {
        'visibility': 'off'
      }
    ]
  }, {
    'featureType': 'road.local',
    'elementType': 'geometry.fill',
    'stylers': [
      {
        'color': '#dddddd'
      }, {
        'lightness': 50
      }, {
        'weight': 1
      }
    ]
  }, {
    'featureType': 'road.local',
    'elementType': 'geometry.stroke',
    'stylers': [
      {
        'color': '#aaaaaa'
      }, {
        'lightness': 50
      }, {
        'weight': 0
      }, {
        'visibility': 'off'
      }
    ]
  }, {
    'featureType': 'transit',
    'stylers': [
      {
        'saturation': -100
      }, {
        'visibility': 'simplified'
      }
    ]
  }, {
    'featureType': 'administrative.province',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  }, {
    'featureType': 'water',
    'elementType': 'labels',
    'stylers': [
      {
        'visibility': 'on'
      }, {
        'lightness': -25
      }, {
        'saturation': -100
      }
    ]
  }, {
    'featureType': 'water',
    'elementType': 'geometry',
    'stylers': [
      {
        'hue': '#ffff00'
      }, {
        'lightness': 25
      }, {
        'saturation': -97
      }
    ]
  }, {
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#777777'
      }
    ]
  }, {
    'elementType': 'labels.text.stroke',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  }, {
    'elementType': 'labels.icon',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  }
];

buildRouteMap = function(destination) {
  var bounds, calcRoute, dmap, infowindow, initdirectionsMap, mapCanvas, mapOptions;
  infowindow = new google.maps.InfoWindow;
  bounds = new google.maps.LatLngBounds;
  mapCanvas = $('.routemap')[0];
  mapOptions = {
    center: myLatlng,
    zoom: 14,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    scrollwheel: false,
    mapTypeControl: false,
    streetViewControl: false
  };
  dmap = void 0;
  calcRoute = function(mapDirect) {
    var curLat, curLng, desLat, desLng, directionsDisplay, directionsService, request;
    directionsService = new google.maps.DirectionsService();
    directionsDisplay = new google.maps.DirectionsRenderer({
      panel: $('.directionlist')[0],
      suppressMarkers: false,
      draggable: true,
      polylineOptions: {
        strokeColor: '#FF7149',
        strokeWeight: 3
      }
    });
    directionsDisplay.setMap(dmap);
    if (typeof currentPos === 'string') {
      currentPos = JSON.parse(currentPos);
    }
    curLat = parseFloat(currentPos.lat);
    curLng = parseFloat(currentPos.lng);
    if (typeof destination === 'string') {
      destination = JSON.parse(destination);
    }
    desLat = parseFloat(destination.lat);
    desLng = parseFloat(destination.lng);
    request = {
      origin: {
        lat: curLat,
        lng: curLng
      },
      destination: {
        lat: desLat,
        lng: desLng
      },
      travelMode: google.maps.TravelMode['DRIVING']
    };
    directionsService.route(request, function(response, status) {
      if (status === google.maps.DirectionsStatus.OK) {
        directionsDisplay.setDirections(response);
      }
    });
  };
  initdirectionsMap = function() {
    var i, image;
    i = void 0;
    image = void 0;
    dmap = new google.maps.Map(mapCanvas, mapOptions);
    calcRoute(dmap);
    dmap.setOptions({
      styles: styles
    });
    return image = {
      url: '/assets/frontoffice/images/mapmarker.svg',
      size: new google.maps.Size(70, 70),
      anchor: new google.maps.Point(35, 35)
    };
  };
  $('.routemetordf').fadeIn();
  $('body').addClass('dirMapOpen');
  return initdirectionsMap();
};

$('body').on('click', '.gotodir', function() {
  var destination, lat, lng;
  lat = $(this).attr('data-lat');
  lng = $(this).attr('data-lng');
  destination = {
    lat: lat,
    lng: lng
  };
  return buildRouteMap(destination);
});

calculateDistance = function(pos) {
  var destinations, lat, lng, origins, service;
  lat = pos.coords.latitude;
  lng = pos.coords.longitude;
  origins = new google.maps.LatLng(lat, lng);
  currentPos = '{"lat": "' + lat + '", "lng": "' + lng + '"}';
  sessionStorage.setItem('currentPos', currentPos);
  destinations = [];
  if (ReiDosFrangos.length > 0) {
    $.each(ReiDosFrangos, function(i, val) {
      var thisval;
      thisval = new google.maps.LatLng(val.lat, val.lng);
      return destinations.push(thisval);
    });
    service = new google.maps.DistanceMatrixService;
    return service.getDistanceMatrix({
      origins: [origins],
      destinations: destinations,
      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: google.maps.UnitSystem.METRIC,
      avoidHighways: false,
      avoidTolls: false
    }, function(data, status) {
      var counter, listofdistances, nearestDist;
      listofdistances = data.rows[0].elements;
      counter = 0;
      $.each(ReiDosFrangos, function(i, val) {
        var distance;
        distance = data.rows[0].elements[counter].distance.value;
        val.distance = distance;
        return counter++;
      });
      ReiDosFrangos.sort(function(a, b) {
        var keyA, keyB;
        keyA = a.distance;
        keyB = b.distance;
        if (keyA < keyB) {
          return -1;
        }
        if (keyA > keyB) {
          return 1;
        }
        return 0;
      });
      $('.bottombar').addClass('visible');
      $('.bottombar .nearestcity').html(ReiDosFrangos[0].city);
      nearestDist = parseInt(ReiDosFrangos[0].distance / 1000);
      $('.bottombar .distance').html(nearestDist + 'km');
      $(function() {
        return setTimeout(function() {
          $('.cityspot[data-id="' + ReiDosFrangos[0].id + '"]').append('<div class="nearest"> <i class="fa fa-map-marker"></i><span class="hint"> Estabelecimento mais próximo</span></div>');
          return $('.cityspot[data-id="' + ReiDosFrangos[0].id + '"]').click();
        }, 500);
      });
      sessionStorage.setItem('nearestCity', ReiDosFrangos[0].city);
      sessionStorage.setItem('nearestDist', nearestDist);
      return sessionStorage.setItem('nearestID', ReiDosFrangos[0].id);
    });
  }
};

$(function() {
  var nearestCity, nearestDist, nearestID;
  nearestCity = sessionStorage.getItem('nearestCity');
  nearestDist = sessionStorage.getItem('nearestDist');
  nearestID = sessionStorage.getItem('nearestID');
  currentPos = sessionStorage.getItem('currentPos');
  if (nearestCity && nearestDist && nearestID && currentPos) {
    $('.bottombar .nearestcity').html(nearestCity);
    $('.bottombar .distance').html(nearestDist + 'km');
    $('.bottombar').addClass('visible');
  } else {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(calculateDistance);
    } else {
      console.log("Browser doesn't support geolocation.");
    }
  }
  if ($('section.stores')[0]) {
    if (nearestCity && nearestDist && nearestID) {
      return $(function() {
        if (nearestID !== '') {
          return setTimeout(function() {
            $('.cityspot[data-id="' + nearestID + '"]').append('<div class="nearest"> <i class="fa fa-map-marker"></i><span class="hint"> Estabelecimento mais perto</span></div>');
            return $('.cityspot[data-id="' + nearestID + '"]').click();
          }, 500);
        }
      });
    }
  }
});

sliderNumber = $('.elslider li').size();

$('.headerimage.active').css('display', 'block');

sliderTiming = 15000;

changetimer = '';

changeSlideImg = function(newactive) {
  $('.headerimage').eq(newactive).addClass('active');
  $('.headerimage').eq(newactive).fadeIn();
  $('.headerimage').eq(newactive).siblings().removeClass('active');
  $('.headerimage').eq(newactive).siblings().fadeOut();
  $('ul.currentspot li').eq(newactive).addClass('active');
  return $('ul.currentspot li').eq(newactive).siblings().removeClass('active');
};

spotCounter = 0;

while (spotCounter < sliderNumber) {
  $('ul.currentspot').append('<li><i class="fa fa-circle"></i></li>');
  spotCounter++;
}

$(function() {
  if (sliderNumber > 0) {
    $('ul.currentspot li').eq(0).addClass('active');
    return changetimer = window.setInterval(function() {
      var currentactive, newactive;
      currentactive = $('.headerimage.active').index();
      if (currentactive + 1 < sliderNumber) {
        newactive = currentactive + 1;
      } else {
        newactive = 0;
      }
      return changeSlideImg(newactive);
    }, sliderTiming);
  }
});

$('body').on('click', 'ul.currentspot li', function() {
  var newvalue;
  newvalue = $(this).index();
  changeSlideImg(newvalue);
  window.clearInterval(changetimer);
  return changetimer = window.setInterval(function() {
    var currentactive, newactive;
    currentactive = $('.headerimage.active').index();
    if (currentactive + 1 < sliderNumber) {
      newactive = currentactive + 1;
    } else {
      newactive = 0;
    }
    return changeSlideImg(newactive);
  }, sliderTiming);
});

$('.arrows').click(function() {
  var active, moveWhere, totalNumSpots;
  moveWhere = $(this).attr('data-target');
  active = $('.currentspot li.active').index();
  totalNumSpots = $('.currentspot li').size();
  if (moveWhere === 'left') {
    if (active > 0) {
      $('.currentspot li').eq(active - 1).click();
    } else {
      $('.currentspot li').eq(totalNumSpots - 1).click();
    }
  }
  if (moveWhere === 'right') {
    if (active >= totalNumSpots - 1) {
      return $('.currentspot li').eq(0).click();
    } else {
      return $('.currentspot li').eq(active + 1).click();
    }
  }
});

url = window.location.href;

if ($('section.stores')[0] && url.indexOf('#mapme') !== -1) {
  $('html,body').animate({
    scrollTop: $('#mapme').offset().top
  }, 1000);
  url = url.replace('#mapme', '');
  window.history.replaceState('', '', url);
}

if ($('section.stores')[0]) {
  $('section.stores').css('pointer', 'default');
  $('a.bottombar').click(function(e) {
    return e.preventDefault();
  });
}

$(function() {
  return setInterval(function() {
    var d, hours, mins, time;
    d = new Date();
    hours = d.getHours();
    mins = d.getMinutes();
    if (hours < 10) {
      hours = '0' + hours;
    }
    if (mins < 10) {
      mins = '0' + mins;
    }
    time = hours + ':' + mins;
    $('span.currenttime').text(time);
    if (hours >= 10 && hours < 22) {
      return $('.effectivestatus').html('Abertos');
    } else {
      return $('.effectivestatus').html('Fechados');
    }
  }, 1000);
});

if (ReiDosFrangos.length > 0) {
  $.each(ReiDosFrangos, function(i, val) {
    $('.encomendar select#where').append('<option value="' + val.id + '">' + val.nome + '</option>');
    if ($('.orderdish')[0]) {
      return $('.orderdish select#fromwhere').append('<option value="' + val.id + '">' + val.nome + '</option>');
    }
  });
  tele = ReiDosFrangos[0].tel;
  if (tele.indexOf('+') > -1) {
    telcontainsplus = true;
  } else {
    telcontainsplus = false;
  }
  tele = tele.replace(/[^\dA-Z]/g, '').replace(/(.{3})/g, '$1 ').trim();
  if (telcontainsplus) {
    tele = '+' + tele;
  }
  $('.encomendar .numberishere').html(tele);
  callablenum = ReiDosFrangos[0].tel.replace(/\s+/g, '');
  $('.encomendar .numberishere').attr('href', 'tel:' + callablenum);
  $('.encomendar .address').html(ReiDosFrangos[0].address + ', ' + ReiDosFrangos[0].city);
  if ($('.orderdish')[0]) {
    $('.orderdish .telefone').html(tele);
    callablenumBuy = ReiDosFrangos[0].tel.replace(/\s+/g, '');
    $('.orderdish .telefone').attr('href', 'tel:' + callablenumBuy);
    $('.orderdish .address').html(ReiDosFrangos[0].address + ', ' + ReiDosFrangos[0].city);
  }
  myLatlng = new google.maps.LatLng(ReiDosFrangos[0].lat, ReiDosFrangos[0].lng);
  firstStore = {
    lat: ReiDosFrangos[0].lat,
    lng: ReiDosFrangos[0].lng
  };
}

fecharMenu = function() {
  var selector, todo;
  $('.thefullmenu').attr('data-status', 'off');
  setTimeout(function() {
    return $('.thefullmenu').css('left', '');
  }, 500);
  $('.fullsizefilter').fadeOut();
  $('.hamburgermenu').children('.hamburger').removeClass('active');
  selector = $('.thefullmenu .content, .thefullmenu .images');
  todo = '';
  selector.css('-webkit-transform', todo);
  selector.css('-moz-transform', todo);
  selector.css('-o-transform', todo);
  selector.css('transform', todo);
  $('.bottombar').css('min-width', '');
  $('.bottombar').css('border-radius', '');
  return $('body').css('overflow', '');
};

$('.hamburgermenu').click(function() {
  var selector, status, todo;
  status = $('.thefullmenu').attr('data-status');
  if (!$('.bottombar').hasClass('visible')) {
    $('.thefullmenu').css('height', '100%');
  }
  if (status === 'off') {
    $('.thefullmenu').attr('data-status', 'on');
    $('.thefullmenu').css('left', '0');
    $('.fullsizefilter').fadeIn();
    $(this).children('.hamburger').addClass('active');
    selector = $('.thefullmenu .content, .thefullmenu .images');
    todo = 'translate3d(0,0,0)';
    selector.css('-webkit-transform', todo);
    selector.css('-moz-transform', todo);
    selector.css('-o-transform', todo);
    selector.css('transform', todo);
    if ($(window).width() >= 600) {
      $('.bottombar').css('min-width', '600px');
      $('.bottombar').css('border-radius', '0');
    } else {
      $('.bottombar').css('min-width', '100%');
      $('.bottombar').css('border-radius', '0');
    }
    return $('body').css('overflow', 'hidden');
  } else {
    return fecharMenu();
  }
});

$('.thefullmenu .content .food').hover((function() {
  return $('.thefullmenu .images .food').addClass('active');
}), function() {
  return $('.thefullmenu .images .food').removeClass('active');
});

$('.thefullmenu .content .business').hover((function() {
  return $('.thefullmenu .images .business').addClass('active');
}), function() {
  return $('.thefullmenu .images .business').removeClass('active');
});

$(document).mouseup(function(e) {
  var container, status, subcontainer;
  container = $('.thefullmenu');
  subcontainer = $('.hamburgermenu');
  status = $('.thefullmenu').attr('data-status');
  if (status === 'on') {
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      if (!subcontainer.is(e.target) && subcontainer.has(e.target).length === 0) {
        fecharMenu();
      }
    }
  }
});

$(document).keyup(function(e) {
  if (e.keyCode === 27) {
    fecharMenu();
  } else {

  }
});

createOrderMap = function() {
  var bounds, infowindow, initGMap, mapCanvas, mapOptionsMini;
  infowindow = new google.maps.InfoWindow;
  bounds = new google.maps.LatLngBounds;
  mapCanvas = $('.mapmicro')[0];
  mapOptionsMini = {
    center: myLatlng,
    zoom: 15,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    scrollwheel: false,
    draggable: true,
    disableDoubleClickZoom: false,
    streetViewControl: false,
    disableDefaultUI: true,
    zoomControl: true
  };
  map = void 0;
  initGMap = function() {
    var i, image, resLat, resLng;
    i = void 0;
    image = void 0;
    marker = void 0;
    map = new google.maps.Map(mapCanvas, mapOptionsMini);
    map.setOptions({
      styles: styles
    });
    image = {
      url: '/assets/frontoffice/images/mapmarker.svg',
      size: new google.maps.Size(70, 70),
      anchor: new google.maps.Point(35, 35)
    };
    i = 0;
    marker = new google.maps.Marker({
      position: myLatlng,
      map: map,
      icon: image,
      clickable: false,
      title: 'Rei dos Frangos'
    });
    resLat = firstStore.lat;
    resLng = firstStore.lng;
    if (currentPos && currentPos !== '') {
      $('.mapmicro .gotodir').remove();
      return $('.mapmicro').append('<div class="gotodir" data-lat="' + resLat + '" data-lng="' + resLng + '">Obter Direcções</div>');
    }
  };
  return initGMap();
};

createBigMap = function() {
  var bounds, infowindow, initGMapBig, mapCanvas, mapOptions;
  infowindow = new google.maps.InfoWindow;
  bounds = new google.maps.LatLngBounds;
  mapCanvas = $('.bigmap')[0];
  mapOptions = {
    center: myLatlng,
    zoom: 14,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    scrollwheel: false,
    mapTypeControl: false,
    streetViewControl: false
  };
  bigmap = void 0;
  initGMapBig = function() {
    var i, image;
    i = void 0;
    image = void 0;
    bigmarker = void 0;
    bigmap = new google.maps.Map(mapCanvas, mapOptions);
    bigmap.setOptions({
      styles: styles
    });
    image = {
      url: '/assets/frontoffice/images/mapmarker.svg',
      size: new google.maps.Size(70, 70),
      anchor: new google.maps.Point(35, 35)
    };
    i = 0;
    return bigmarker = new google.maps.Marker({
      position: myLatlng,
      map: bigmap,
      icon: image,
      clickable: false,
      title: 'Rei dos Frangos'
    });
  };
  return initGMapBig();
};

createHQMap = function() {
  var hqcoords, hqpos, infowindow, initHQGMap, mapCanvas, mapOptionsHQ;
  hqcoords = $('.micromaphq').attr('data-gps');
  hqcoords = hqcoords.split(',');
  hqpos = new google.maps.LatLng(hqcoords[0], hqcoords[1]);
  infowindow = new google.maps.InfoWindow;
  mapCanvas = $('.micromaphq')[0];
  mapOptionsHQ = {
    center: hqpos,
    zoom: 15,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    scrollwheel: false,
    draggable: true,
    disableDoubleClickZoom: false,
    streetViewControl: false,
    disableDefaultUI: true,
    zoomControl: true
  };
  map = void 0;
  initHQGMap = function() {
    var i, image, loadbutton;
    i = void 0;
    image = void 0;
    marker = void 0;
    map = new google.maps.Map(mapCanvas, mapOptionsHQ);
    map.setOptions({
      styles: styles
    });
    image = {
      url: '/assets/frontoffice/images/mapmarker.svg',
      size: new google.maps.Size(70, 70),
      anchor: new google.maps.Point(35, 35)
    };
    marker = new google.maps.Marker({
      position: hqpos,
      map: map,
      icon: image,
      clickable: false,
      title: 'Rei dos Frangos'
    });
    return loadbutton = setInterval(function() {
      var coords, resLat, resLng;
      if (currentPos && currentPos !== '') {
        coords = $('.micromaphq').attr('data-gps');
        coords = coords.split(',');
        resLat = coords[0];
        resLng = coords[1];
        $('.micromaphq .gotodir').remove();
        $('.micromaphq').append('<div class="gotodir" data-lat="' + resLat + '" data-lng="' + resLng + '">Obter Direcções</div>');
        return clearInterval(loadbutton);
      }
    }, 200);
  };
  return initHQGMap();
};

if ($('.bigmap')[0]) {
  bigmapzoom = 0;
  createBigMap();
  if ($(window).width() > 750) {
    bigmap.panBy(-190, 0);
    bigmapzoom = bigmap.getZoom();
    bigmap.addListener('zoom_changed', function() {
      var newzoom, oldzoom;
      newzoom = bigmap.getZoom();
      oldzoom = bigmapzoom;
      if (newzoom > oldzoom) {
        bigmap.panBy(190, 0);
      } else {
        bigmap.panBy(-95, 0);
      }
      return bigmapzoom = bigmap.getZoom();
    });
  }
  if ($(window).width() < 750) {
    bigmap.setOptions({
      draggable: false
    });
  }
}

if ($('.micromaphq')[0]) {
  createHQMap();
}

fecharEncomendas = function() {
  $('.encomendar').stop().attr('data-status', 'off');
  $('.encomendar').stop().css('right', '');
  $('.fullsizefilter').stop().fadeOut();
  $('.encomendas').stop().removeClass('active');
  return $('body').stop().css('overflow', '');
};

$('.encomendas').click(function() {
  var mapstatus, status;
  status = $('.encomendar').attr('data-status');
  if (status === 'off') {
    $('.encomendar').stop().attr('data-status', 'on');
    $('.encomendar').stop().css('right', '0');
    $('.fullsizefilter').stop().fadeIn();
    $(this).stop().addClass('active');
    $('body').stop().css('overflow', 'hidden');
    mapstatus = $('.mapmicro').attr('data-loaded');
    if ($('.mapmicro')[0] && mapstatus !== 'true') {
      return setTimeout(function() {
        $('.mapmicro').attr('data-loaded', 'true');
        return createOrderMap();
      }, 500);
    }
  } else {
    return fecharEncomendas();
  }
});

$(document).mouseup(function(e) {
  var container, status, subcontainer;
  container = $('.encomendar');
  subcontainer = $('.encomendas');
  status = $('.encomendar').attr('data-status');
  if (status === 'on') {
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      if (!subcontainer.is(e.target) && subcontainer.has(e.target).length === 0) {
        fecharEncomendas();
      }
    }
  }
});

$(document).keyup(function(e) {
  if (e.keyCode === 27) {
    fecharEncomendas();
  } else {

  }
});

$('select#where').change(function() {
  var LatLng, idtofetch, resLat, resLng, result;
  idtofetch = parseInt($('select#where option:selected').val());
  result = $.grep(ReiDosFrangos, function(e) {
    return e.id === idtofetch;
  });
  tele = result[0].tel;
  if (tele.indexOf('+') > -1) {
    telcontainsplus = true;
  } else {
    telcontainsplus = false;
  }
  tele = tele.replace(/[^\dA-Z]/g, '').replace(/(.{3})/g, '$1 ').trim();
  if (telcontainsplus) {
    tele = '+' + tele;
  }
  $('.encomendar .numberishere').html(tele);
  callablenum = result[0].tel.replace(/\s+/g, '');
  $('.encomendar .numberishere').attr('href', 'tel:' + callablenum);
  $('.encomendar .address').html(result[0].address + ', ' + result[0].city);
  resLat = parseFloat(result[0].lat);
  resLng = parseFloat(result[0].lng);
  LatLng = new google.maps.LatLng(resLat, resLng);
  if (currentPos && currentPos !== '') {
    $('.mapmicro .gotodir').remove();
    $('.mapmicro').append('<div class="gotodir" data-lat="' + resLat + '" data-lng="' + resLng + '">Obter Direcções</div>');
  }
  map.setCenter(LatLng);
  return marker.setPosition(LatLng);
});

$('select#fromwhere').change(function() {
  var idtofetch, result;
  idtofetch = parseInt($('select#fromwhere option:selected').val());
  result = $.grep(ReiDosFrangos, function(e) {
    return e.id === idtofetch;
  });
  tele = result[0].tel;
  if (tele.indexOf('+') > -1) {
    telcontainsplus = true;
  } else {
    telcontainsplus = false;
  }
  tele = tele.replace(/[^\dA-Z]/g, '').replace(/(.{3})/g, '$1 ').trim();
  if (telcontainsplus) {
    tele = '+' + tele;
  }
  $('.orderdish .telefone').html(tele);
  callablenum = result[0].tel.replace(/\s+/g, '');
  $('.orderdish .telefone').attr('href', 'tel:' + callablenum);
  return $('.orderdish .address').html(result[0].address + ', ' + result[0].city);
});

if (!$('.noscrollheader')[0]) {
  $(document).scroll(function() {
    var scrolled, scrolledCSS;
    scrolled = $(document).scrollTop();
    if (scrolled <= 500 && $(window).width() > 600) {
      scrolledCSS = 'translate3d(0,' + scrolled / 4 + 'px,0)';
      $('.headerimage').css('-webkit-transform', scrolledCSS);
      $('.headerimage').css('-moz-transform', scrolledCSS);
      $('.headerimage').css('-o-transform', scrolledCSS);
      return $('.headerimage').css('transform', scrolledCSS);
    }
  });
}

selector = subselector = otherSelector = '';

$('.menucontainer ul.themenu li').hover((function() {
  var texttoshow;
  texttoshow = $(this).attr('data-explain');
  selector = $(this).parent().parent().parent().parent().siblings('.showdetails');
  otherSelector = $(this).parent().parent().parent().parent().siblings('.whilenodetails');
  subselector = selector.children('.table').children('.tablecell').children('.menutextgoeshere');
  if (typeof texttoshow !== 'undefined') {
    subselector.stop().html(texttoshow);
    selector.stop().fadeIn();
    return otherSelector.stop().fadeOut();
  }
}), function() {
  otherSelector.stop().fadeIn();
  return selector.stop().fadeOut(400, function() {
    return subselector.stop().html('');
  });
});

chartsloaded = false;

optionsCharts = {
  showTooltips: false,
  percentageInnerCutout: 97,
  animationEasing: 'easeInOutExpo',
  segmentShowStroke: false
};

thecolor = '#c90709';

if ($('.infograph')[0]) {
  $('.dishstats .stat').each(function() {
    var ctx, current, data, diff, max, newChart;
    ctx = $(this).find('canvas').get(0).getContext("2d");
    current = parseFloat($(this).find('.infograph').attr('data-value'));
    max = $(this).find('.infograph').attr('data-max');
    diff = parseFloat(max - current);
    data = [
      {
        value: current,
        color: thecolor
      }, {
        value: diff,
        color: 'transparent'
      }
    ];
    newChart = new Chart(ctx).Doughnut(data, optionsCharts);
    $(this).find('.valuetocount').animate({
      Counter: current
    }, {
      duration: 1500,
      easing: 'swing',
      step: function(now) {
        $(this).text(Math.ceil(now));
      }
    });
  });
}

closeGalleryModal = function() {
  return $('.gallerymodal img').animate({
    opacity: 0
  }, 400, function() {
    $('.gallerymodal img').attr('src', '');
    $('.gallerymodal').slideUp(400);
    return $('body').css('overflow', '');
  });
};

$('.imagegallery .image').click(function() {
  var maximgheight, target, windowheight;
  target = $(this).attr('data-target');
  $('body').css('overflow', 'hidden');
  windowheight = $(window).height();
  maximgheight = windowheight * 0.8;
  $('.gallerymodal img').css('max-height', maximgheight + 'px');
  $('.gallerymodal img').attr('src', target);
  $('.gallerymodal img').css('opacity', 0);
  return $('.gallerymodal').slideDown(400, function() {
    return $('.gallerymodal img').animate({
      opacity: 1
    }, 400);
  });
});

$('section.gallerymodal .closeme').click(function() {
  return closeGalleryModal();
});

$(document).keyup(function(e) {
  if (e.keyCode === 27) {
    closeGalleryModal();
  } else {

  }
});

if ($('.mapcontainer')[0]) {
  previouscity = '';
  RDF = ReiDosFrangos;
  RDF.sort(function(a, b) {
    var keyA, keyB;
    keyA = a.city;
    keyB = b.city;
    if (keyA < keyB) {
      return -1;
    }
    if (keyA > keyB) {
      return 1;
    }
    return 0;
  });
  counter = 0;
  $.each(RDF, function(i, val) {
    var call, theclass;
    theclass = '';
    selector = $('.mapcontainer .spotscontainer');
    if (val.city !== previouscity) {
      selector.append('<div class="city">' + val.city + '</div>');
      previouscity = val.city;
    }
    if (counter === 0) {
      theclass = 'active';
    }
    tele = val.tel;
    if (tele.indexOf('+') > -1) {
      telcontainsplus = true;
    } else {
      telcontainsplus = false;
    }
    tele = tele.replace(/[^\dA-Z]/g, '').replace(/(.{3})/g, '$1 ').trim();
    if (telcontainsplus) {
      tele = '+' + tele;
    }
    call = val.tel.replace(/\s+/g, '');
    selector.append('<div class="cityspot ' + theclass + '" data-lat="' + val.lat + '" data-lng="' + val.lng + '" data-id="' + val.id + '"> <div class="address">' + val.address + '</div> <a class="tel" href="tel:' + call + '">' + tele + '</a> </div>');
    return counter++;
  });
  $('span.storecounter').text(counter);
}

if ($('.mapcontainer')[0]) {
  altura = $('.spotscontainer').outerHeight();
  $('.mapcontainer').css('height', altura);
  if (altura >= $(window).height()) {
    $('.bigmap').css('height', $(window).height() + 'px');
  } else {
    $('.bigmap').css('height', altura);
  }
  mapoffset = $('.bigmap').offset().top;
  $(document).scroll(function() {
    var diff, difftwo, listheight, offset, scrolled;
    if ($(window).width() > 750) {
      if (altura >= $(window).height()) {
        $('.bigmap').css('height', $(window).height() + 'px');
      } else {
        $('.bigmap').css('height', altura);
      }
      scrolled = $(document).scrollTop();
      diff = scrolled - mapoffset;
      if (diff >= 0) {
        $('.bigmap').css('position', 'fixed');
        $('.bigmap').css('bottom', '0px');
      } else if (diff < 0) {
        $('.bigmap').css('position', '');
        $('.bigmap').css('bottom', '');
      }
      listheight = $('.spotscontainer').outerHeight();
      offset = $('.spotscontainer').offset().top;
      difftwo = listheight + offset - (scrolled + $(window).height());
      if (difftwo < 0) {
        $('.bigmap').css('position', 'absolute');
        return $('.bigmap').css('bottom', '0px');
      }
    }
  });
}

$('body').on('click', 'section.stores .spotscontainer .cityspot', function(e) {
  var LatLng, lat, lng;
  if (!e.originalEvent) {
    if ($(window).width() > 750) {
      $('html, body').animate({
        scrollTop: $(this).offset().top - 200
      }, 1000);
    }
  }
  $(this).addClass('active');
  $(this).siblings().removeClass('active');
  lat = $(this).attr('data-lat');
  lng = $(this).attr('data-lng');
  if (currentPos && currentPos !== '') {
    $('.bigmap .gotodir').remove();
    $('.bigmap').append('<div class="gotodir" data-lat="' + lat + '" data-lng="' + lng + '">Obter Direcções</div>');
  }
  LatLng = new google.maps.LatLng(lat, lng);
  bigmap.setCenter(LatLng);
  if ($(window).width() > 750) {
    bigmap.panBy(-190, 0);
  }
  if ($(window).width() < 750) {
    $('html,body').animate({
      scrollTop: $('#mapme').offset().top - 70
    }, 500);
  }
  return bigmarker.setPosition(LatLng);
});

offset = 0;

eachmargin = 20;

productsize = 0;

availClass = [];

$('ul.productlist li').each(function() {
  var already, thisCl;
  thisCl = $(this).attr('data-class');
  already = $.inArray(thisCl, availClass);
  if (already === -1) {
    return availClass.push(thisCl);
  }
});

$('ul.groups li').each(function() {
  var already, thissec;
  thissec = $(this).attr('data-section');
  already = $.inArray(thissec, availClass);
  if (already === -1) {
    return $(this).remove();
  }
});

resizeProducts = function() {
  var container, containerwidth, eachwidth, finalwidth, productnumber, windowwidth;
  container = $('.productscontainer').innerWidth();
  windowwidth = $(window).width();
  if (windowwidth > 1500) {
    productsize = container / 5;
    productsize = productsize - (eachmargin * 2);
    $('section.products ul.productlist li').css('width', productsize + 'px');
  } else if (windowwidth <= 1500 && windowwidth > 1200) {
    productsize = container / 4;
    productsize = productsize - (eachmargin * 2);
    $('section.products ul.productlist li').css('width', productsize + 'px');
  } else if (windowwidth <= 1200 && windowwidth > 750) {
    productsize = container / 3;
    productsize = productsize - (eachmargin * 2);
    $('section.products ul.productlist li').css('width', productsize + 'px');
  } else if (windowwidth <= 750) {
    productsize = container / 2;
    productsize = productsize - (eachmargin * 2);
    $('section.products ul.productlist li').css('width', productsize + 'px');
  }
  productnumber = $('ul.productlist li').size();
  containerwidth = $('.productscontainer').innerWidth();
  eachwidth = parseInt(productsize) + parseInt(eachmargin * 2);
  finalwidth = eachwidth * productnumber;
  finalwidth = finalwidth + productnumber;
  return $('.productlist').css('width', finalwidth + 'px');
};

$(function() {
  if ($('.productscontainer')[0]) {
    return resizeProducts();
  }
});

$(window).resize(function() {
  if ($('.productscontainer')[0]) {
    resizeProducts();
  }
  $('section.products ul.productlist').css('-webkit-transform', 'translate3d(0,0,0)');
  $('section.products ul.productlist').css('-moz-transform', 'translate3d(0,0,0)');
  $('section.products ul.productlist').css('-o-transform', 'translate3d(0,0,0)');
  $('section.products ul.productlist').css('transform', 'translate3d(0,0,0)');
  $('section.products ul.groups li:first-child').addClass('active');
  $('section.products ul.groups li:first-child').siblings().removeClass('active');
  return offset = 0;
});

setOffset = function(offset) {
  $('section.products ul.productlist').css('-webkit-transform', 'translate3d(-' + offset + 'px,0,0)');
  $('section.products ul.productlist').css('-moz-transform', 'translate3d(-' + offset + 'px,0,0)');
  $('section.products ul.productlist').css('-o-transform', 'translate3d(-' + offset + 'px,0,0)');
  return $('section.products ul.productlist').css('transform', 'translate3d(-' + offset + 'px,0,0)');
};

$('section.products ul.groups li').click(function() {
  var choice, eachwidth, extra, windowwidth;
  windowwidth = $(window).width();
  eachwidth = parseInt(productsize) + parseInt(eachmargin * 2);
  $(this).addClass('active');
  $(this).siblings().removeClass('active');
  extra = windowwidth * 0.1;
  choice = $(this).attr('data-section');
  offset = $('li[data-class="' + choice + '"]').first().index();
  offset = offset * parseInt(eachwidth);
  return setOffset(offset);
});

$('section.products .slideleft').click(function() {
  var eachwidth, getVisible, section;
  eachwidth = parseInt(productsize) + parseInt(eachmargin * 2);
  if (offset - eachwidth >= 0) {
    offset = offset - eachwidth;
    getVisible = offset / eachwidth;
    section = $('ul.productlist li').eq(getVisible).attr('data-class');
    selector = $('ul.groups li[data-section="' + section + '"]');
    selector.addClass('active');
    selector.siblings().removeClass('active');
    return setOffset(offset);
  }
});

$('section.products .slideright').click(function() {
  var eachwidth, getVisible, listwidth, section;
  eachwidth = parseInt(productsize) + parseInt(eachmargin * 2);
  listwidth = parseInt($('ul.productlist').css('width'));
  if (offset + eachwidth <= listwidth - eachwidth) {
    offset = offset + eachwidth;
    getVisible = offset / eachwidth;
    section = $('ul.productlist li').eq(getVisible).attr('data-class');
    selector = $('ul.groups li[data-section="' + section + '"]');
    selector.addClass('active');
    selector.siblings().removeClass('active');
    return setOffset(offset);
  }
});

if ($('section.ementadodia')[0]) {
  where = ['topleft', 'topright', 'bottomleft', 'bottomright'];
  i = 0;
  while (i < 10) {
    randWhere = where[Math.floor(Math.random() * where.length)];
    randtop = parseInt(Math.random() * 100) - 50;
    randleft = parseInt(Math.random() * 100) - 50;
    triangleMultiplier = 1500;
    height = parseInt(Math.random() * triangleMultiplier);
    width = parseInt(Math.random() * triangleMultiplier);
    switch (randWhere) {
      case 'topleft':
        size = 'border-width: ' + height + 'px ' + width + 'px 0 0';
        break;
      case 'topright':
        size = 'border-width: 0 ' + height + 'px ' + width + 'px 0';
        break;
      case 'bottomright':
        size = 'border-width: 0 0 ' + height + 'px ' + width + 'px';
        break;
      case 'bottomleft':
        size = 'border-width: ' + height + 'px 0 0 ' + width + 'px';
    }
    $('section.ementadodia .background .trianglescontainer').append('<div class="triangle ' + randWhere + '" style="top: ' + randtop + '%; left: ' + randleft + '%; ' + size + ';"></div>');
    i++;
  }
}

nexttext = $('section.newsletterbig .next').text();

$('section.newsletterbig .next').click(function() {
  offset = $('.inputscontainer').attr('data-visible');
  if (offset < 3) {
    offset++;
    $('.inputscontainer').attr('data-visible', offset);
    $('.currentinput span.counter').text(offset);
    switch (offset) {
      case 2:
        $('section.newsletterbig .prev').fadeIn();
        $('section.newsletterbig .next').text(nexttext);
        $('.inputscontainer').css('-webkit-transform', 'translate3d(-320px,0,0)');
        $('.inputscontainer').css('-moz-transform', 'translate3d(-320px,0,0)');
        $('.inputscontainer').css('-o-transform', 'translate3d(-320px,0,0)');
        $('.inputscontainer').css('transform', 'translate3d(-320px,0,0)');
        $('section.newsletterbig .next').show();
        return $('section.newsletterbig input.bigformsubmit').hide();
      case 3:
        $('section.newsletterbig .prev').fadeIn();
        $('.inputscontainer').css('-webkit-transform', 'translate3d(-640px,0,0)');
        $('.inputscontainer').css('-moz-transform', 'translate3d(-640px,0,0)');
        $('.inputscontainer').css('-o-transform', 'translate3d(-640px,0,0)');
        $('.inputscontainer').css('transform', 'translate3d(-640px,0,0)');
        $('section.newsletterbig .next').hide();
        return $('section.newsletterbig input.bigformsubmit').show();
    }
  }
});

$('section.newsletterbig .prev').click(function() {
  offset = $('.inputscontainer').attr('data-visible');
  if (offset > 1) {
    offset--;
    $('.inputscontainer').attr('data-visible', offset);
    $('.currentinput span.counter').text(offset);
    switch (offset) {
      case 1:
        $('section.newsletterbig .prev').fadeOut();
        $('.inputscontainer').css('-webkit-transform', 'translate3d(0,0,0)');
        $('.inputscontainer').css('-moz-transform', 'translate3d(0,0,0)');
        $('.inputscontainer').css('-o-transform', 'translate3d(0,0,0)');
        return $('.inputscontainer').css('transform', 'translate3d(0,0,0)');
      case 2:
        $('section.newsletterbig .prev').fadeIn();
        $('.inputscontainer').css('-webkit-transform', 'translate3d(-320px,0,0)');
        $('.inputscontainer').css('-moz-transform', 'translate3d(-320px,0,0)');
        $('.inputscontainer').css('-o-transform', 'translate3d(-320px,0,0)');
        $('.inputscontainer').css('transform', 'translate3d(-320px,0,0)');
        $('section.newsletterbig .next').show();
        return $('section.newsletterbig input.bigformsubmit').hide();
    }
  }
});

$(document).on('keypress', 'form.bigform', function(e) {
  var offsetInt;
  if (e.keyCode === 13) {
    e.preventDefault();
    offset = $('.inputscontainer').attr('data-visible');
    offsetInt = parseInt(offset);
    if (offset === '3') {
      return $('form.bigform').submit();
    } else {
      $('section.newsletterbig .next').click();
      return $('.inputscontainer input').eq(offsetInt).select();
    }
  }
});

$(document).on('keydown', 'form.bigform', function(e) {
  var offsetInt;
  if (e.keyCode === 9) {
    e.preventDefault();
    offset = $('.inputscontainer').attr('data-visible');
    offsetInt = parseInt(offset);
    if (offset !== '3') {
      $('section.newsletterbig .next').click();
      return $('.inputscontainer input').eq(offsetInt).select();
    }
  }
});

firstCateg = true;

$('section.dishlist .category .what').each(function() {
  var id, name;
  if (firstCateg) {
    firstCateg = false;
    $('ul.gotocateg').append('<li data-categtoview="all" class="active"><a href="#">Todos</a></li>');
  }
  name = $(this).text();
  name = name.toLowerCase();
  id = $(this).parent().attr('data-categ');
  $(this).parent().attr('id', name);
  return $('ul.gotocateg').append('<li data-categtoview="' + id + '"><a href="#' + name + '">' + name + '</a></li>');
});

showCateg = function(categid) {
  $('.category:not([data-categ="' + categid + '"])').hide();
  $('.categoryDishes:not([data-categ="' + categid + '"])').hide();
  $('.category[data-categ="' + categid + '"]').show();
  return $('.categoryDishes[data-categ="' + categid + '"]').show();
};

$('body').on('click', 'ul.gotocateg li a', function(e) {
  var button, categid, status;
  e.preventDefault();
  categid = $(this).parent().attr('data-categtoview');
  status = $(this).parent().hasClass('active');
  selector = $('.categoryDishes[data-categ="' + categid + '"]');
  button = $(this).parent();
  button.addClass('active');
  button.siblings().removeClass('active');
  if (categid !== 'all') {
    return showCateg(categid);
  } else if (categid === 'all') {
    $('.category').show();
    return $('.categoryDishes').show();
  }
});

if ($('section.contacts')[0]) {
  $('section.contacts input').focus(function() {
    return $(this).siblings('label').addClass('backtoplace');
  });
  $('section.contacts input').focusout(function() {
    var valor;
    valor = $(this).val();
    if (valor === '') {
      return $(this).siblings('label').removeClass('backtoplace');
    }
  });
  $('section.contacts textarea').focus(function() {
    return $(this).siblings('label').addClass('backtoplace');
  });
  $('section.contacts textarea').focusout(function() {
    var valor;
    valor = $(this).val();
    if (valor === '') {
      return $(this).siblings('label').removeClass('backtoplace');
    }
  });
}

$('body').on('mouseenter', '.cityspot .nearest i.fa', function() {
  return $(this).siblings().addClass('visible');
});

$('body').on('mouseleave', '.cityspot .nearest i.fa', function() {
  return $(this).siblings().removeClass('visible');
});

$('.socialshare a.twitter').click(function(e) {
  var left, opts, top;
  e.preventDefault();
  width = 575;
  height = 400;
  left = ($(window).width() - width) / 2;
  top = ($(window).height() - height) / 2;
  url = this.href;
  opts = 'status=1' + ',width=' + width + ',height=' + height + ',top=' + top + ',left=' + left;
  return window.open(url, 'twitter', opts);
});

$('.socialshare a.facebook').click(function(e) {
  var currentURL;
  e.preventDefault();
  currentURL = window.location.href;
  return FB.ui({
    method: 'share',
    href: currentURL
  }, function(response) {});
});

$(function() {
  var checkTimeline;
  return checkTimeline = setInterval(function() {
    var options, pageAccessToken;
    if (fbloaded) {
      pageAccessToken = '1659266797684694|J9mTeUAjp186w55XGrPQ_9UNqBw';
      url = '/201927525852/posts';
      options = '?fields=link,full_picture,message,created_time';
      FB.api(url + options, {
        access_token: pageAccessToken
      }, function(response) {
        var info, postscounter;
        if (response && !response.error) {
          info = response.data;
          $('.titlefb').fadeIn();
          postscounter = 0;
          info.forEach(function(item) {
            var date, message;
            if (postscounter < 5) {
              date = new Date(item.created_time);
              date = date.toLocaleDateString('pt-PT');
              message = item.message;
              if (message.length > 250) {
                message += '<div class="hidderofmsg"></div>';
              }
              if (typeof item.link === 'undefined') {
                if (item.full_picture !== '' && typeof item.full_picture !== 'undefined') {
                  $('.fbfeed ul').append('<li> <div class="imgcontain"><img class="picture" src="' + item.full_picture + '"></div><div class="fbcontain"> <div class="table"><div class="tablecell"> <div class="message">' + message + '</div><div class="date">' + date + '</div></div></div></div></li>');
                } else {
                  $('.fbfeed ul').append('<li> <div class="fbcontain visible"> <div class="table"><div class="tablecell"> <div class="message">' + message + '</div><div class="date">' + date + '</div></div></div></div></li>');
                }
              } else {
                if (item.full_picture !== '' && typeof item.full_picture !== 'undefined') {
                  $('.fbfeed ul').append('<li> <a href="' + item.link + '" target="_blank"> <div class="imgcontain"><img class="picture" src="' + item.full_picture + '"></div><div class="fbcontain"> <div class="table"><div class="tablecell"> <div class="message">' + message + '</div><div class="date">' + date + '</div></div></div></div></a></li>');
                } else {
                  $('.fbfeed ul').append('<li> <a href="' + item.link + '" target="_blank"> <div class="fbcontain visible"> <div class="table"><div class="tablecell"> <div class="message">' + message + '</div><div class="date">' + date + '</div></div></div></div></a></li>');
                }
              }
            }
            return postscounter++;
          });
        } else {
          console.log(response.error);
        }
      });
      return clearInterval(checkTimeline);
    }
  }, 100);
});

hintsLoaded = sessionStorage.getItem('hintsseen');

if (hintsLoaded) {
  $('.hintencomendas, .hintencomendas').remove();
} else {
  setTimeout(function() {
    $('.hintencomendas, .hintencomendas').fadeIn();
    return setTimeout(function() {
      return $('.hintencomendas, .hintencomendas').fadeOut();
    }, 7500);
  }, 1000);
  sessionStorage.setItem('hintsseen', true);
}

if ($('section.contacts')[0]) {
  tel = $('.telefonehere a').text();
  if (tel.indexOf('+') > -1) {
    telcontainsplus = true;
  } else {
    telcontainsplus = false;
  }
  tel = tel.replace(/[^\dA-Z]/g, '').replace(/(.{3})/g, '$1 ').trim();
  if (telcontainsplus) {
    tel = '+' + tel;
  }
  $('.telefonehere a').text(tel);
}

$('section.header .headerimage .slogan a').click(function(e) {
  var desc, img, name, status;
  e.preventDefault();
  img = $(this).attr('data-camp-img');
  name = $(this).attr('data-camp-name');
  desc = $(this).attr('data-camp-desc');
  status = $('.campaignmodal').css('display');
  if (status !== 'block') {
    $('.campaignmodal .name').text(name);
    $('.campaignmodal .phrase').text(desc);
    $('.campaignmodal .image img').attr('src', img);
    return $('.campaignmodal').fadeIn(400, function() {
      return $('.stuffgoeshere').fadeIn(400);
    });
  }
});

$('.campaignmodal .thecrosse').click(function() {
  return $('.stuffgoeshere').fadeOut(400, function() {
    return $('.campaignmodal').fadeOut(400, function() {
      $('.campaignmodal .name, .campaignmodal .phrase').text('');
      return $('.campaignmodal .image img').attr('src', '');
    });
  });
});

$(document).keyup(function(e) {
  if (e.keyCode === 27) {
    return $('.stuffgoeshere').fadeOut(400, function() {
      return $('.campaignmodal').fadeOut(400, function() {
        $('.campaignmodal .name, .campaignmodal .phrase').text('');
        return $('.campaignmodal .image img').attr('src', '');
      });
    });
  }
});

if ($('.notif')[0]) {
  setTimeout(function() {
    return $('.notif').slideDown();
  }, 500);
  setTimeout(function() {
    return $('.notif').slideUp();
  }, 10000);
  $('.notif .closememodal').click(function() {
    return $('.notif').slideUp();
  });
}

$('.routemetordf .closeme').click(function() {
  $('.routemetordf').fadeOut(400, function() {
    return $('.routemetordf .directionlist').empty();
  });
  return $('body').removeClass('dirMapOpen');
});

$(document).keyup(function(e) {
  if (e.keyCode === 27) {
    $('.routemetordf').fadeOut(400, function() {
      return $('.routemetordf .directionlist').empty();
    });
    return $('body').removeClass('dirMapOpen');
  }
});

$(function() {
  console.log('%cRei dos Frangos', 'color:#fff;background:#333; text-transform: uppercase; font-weight: 700; padding: 3px 10px;');
  console.log('%cwebsite by amplified creations', 'color:#f16622;font-family: "Ubuntu Mono";');
  console.log('%c> https://amplifiedcreations.com', 'color:#333; font-family: "Ubuntu Mono"; font-style: italic;');
  return console.log('%c-------------', 'color:#eee;');
});
