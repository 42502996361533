# global vars
map = ''
bigmap = ''
myLatlng = ''
marker = ''
bigmarker = ''
currentPos = ''

styles = [
    {
        'featureType': 'landscape'
        'stylers': [
            { 'saturation': -100 }
            { 'lightness': 100 }
            { 'visibility': 'on' }
        ]
    }
    {
        'featureType': 'poi'
        'stylers': [
            { 'saturation': -100 }
            { 'lightness': 51 }
            { 'visibility': 'simplified' }
        ]
    }
    {
        'featureType': 'road.highway',
        'elementType': 'geometry.fill',
        'stylers': [
            { 'color': '#dddddd' },
            { 'lightness': 50 },
            { 'weight': 2.2 }
        ]
    }
    {
        'featureType': 'road.highway',
        'elementType': 'geometry.stroke',
        'stylers': [
            { 'color': '#aaaaaa' },
            { 'lightness': 50 },
            { 'weight': 0 }
            { 'visibility': 'off' }
        ]
    }
    {
        'featureType': 'road.arterial',
        'elementType': 'geometry.fill',
        'stylers': [
            { 'color': '#dddddd' },
            { 'lightness': 50 },
            { 'weight': 1 }
        ]
    }
    {
        'featureType': 'road.arterial',
        'elementType': 'geometry.stroke',
        'stylers': [
            { 'color': '#aaaaaa' },
            { 'lightness': 50 },
            { 'weight': 0 }
            { 'visibility': 'off' }
        ]
    }
    {
        'featureType': 'road.local',
        'elementType': 'geometry.fill',
        'stylers': [
            { 'color': '#dddddd' },
            { 'lightness': 50 },
            { 'weight': 1 }
        ]
    }
    {
        'featureType': 'road.local',
        'elementType': 'geometry.stroke',
        'stylers': [
            { 'color': '#aaaaaa' },
            { 'lightness': 50 },
            { 'weight': 0 }
            { 'visibility': 'off' }
        ]
    }
    {
        'featureType': 'transit'
        'stylers': [
            { 'saturation': -100 }
            { 'visibility': 'simplified' }
        ]
    }
    {
        'featureType': 'administrative.province'
        'stylers': [ { 'visibility': 'off' } ]
    }
    {
        'featureType': 'water'
        'elementType': 'labels'
        'stylers': [
            { 'visibility': 'on' }
            { 'lightness': -25 }
            { 'saturation': -100 }
        ]
    }
    {
        'featureType': 'water'
        'elementType': 'geometry'
        'stylers': [
            { 'hue': '#ffff00' }
            { 'lightness': 25 }
            { 'saturation': -97 }
        ]
    }
    {
        'elementType': 'labels.text.fill',
        'stylers': [
            { 'color': '#777777' }
        ]
    }
    {
        'elementType': 'labels.text.stroke',
        'stylers': [
            { 'visibility': 'off' }
        ]
    }
    {
        'elementType': 'labels.icon',
        'stylers': [
            { 'visibility': 'off' }
        ]
    }
]
# global vars - end

# gets user location
buildRouteMap = (destination) ->
  infowindow = new (google.maps.InfoWindow)
  bounds = new (google.maps.LatLngBounds)
  mapCanvas = $('.routemap')[0]
  mapOptions =
      center: myLatlng
      zoom: 14
      mapTypeId: google.maps.MapTypeId.ROADMAP
      scrollwheel: false
      mapTypeControl: false
      streetViewControl: false
  dmap = undefined

  calcRoute = (mapDirect) ->
    directionsService = new google.maps.DirectionsService()

    directionsDisplay = new google.maps.DirectionsRenderer({
      panel: $('.directionlist')[0]
      suppressMarkers: false
      draggable: true
      polylineOptions: {
        strokeColor: '#FF7149'
        strokeWeight: 3
      }
    })

    directionsDisplay.setMap dmap

    if typeof currentPos is 'string'
      currentPos = JSON.parse(currentPos)

    curLat = parseFloat(currentPos.lat)
    curLng = parseFloat(currentPos.lng)

    if typeof destination is 'string'
      destination = JSON.parse(destination)

    desLat = parseFloat(destination.lat)
    desLng = parseFloat(destination.lng)


    request =
      origin: {lat: curLat, lng: curLng}
      destination: {lat: desLat, lng: desLng}
      travelMode: google.maps.TravelMode['DRIVING']

    directionsService.route request, (response, status) ->
      if status == google.maps.DirectionsStatus.OK
        directionsDisplay.setDirections response
      return
    return

  initdirectionsMap = ->
    i = undefined
    image = undefined
    dmap = new (google.maps.Map)(mapCanvas, mapOptions)
    calcRoute(dmap)
    dmap.setOptions styles: styles
    image =
        url: '/assets/frontoffice/images/mapmarker.svg'
        size: new (google.maps.Size)(70, 70)
        anchor: new (google.maps.Point)(35, 35)

  $('.routemetordf').fadeIn()
  $('body').addClass 'dirMapOpen'

  initdirectionsMap()

$('body').on 'click', '.gotodir', ->
  lat = $(@).attr 'data-lat'
  lng = $(@).attr 'data-lng'
  destination = {lat: lat, lng: lng}
  buildRouteMap(destination)

calculateDistance = (pos) ->
  lat = pos.coords.latitude
  lng = pos.coords.longitude
  origins = new (google.maps.LatLng)(lat, lng)
  currentPos = '{"lat": "' + lat + '", "lng": "' + lng + '"}'

  sessionStorage.setItem 'currentPos', currentPos

  destinations = []

  if ReiDosFrangos.length > 0
    $.each ReiDosFrangos, (i, val) ->
      # builds destinations array
      thisval = new (google.maps.LatLng)(val.lat, val.lng)
      destinations.push thisval

    service = new (google.maps.DistanceMatrixService)
    service.getDistanceMatrix {
      origins: [origins]
      destinations: destinations
      travelMode: google.maps.TravelMode.DRIVING
      unitSystem: google.maps.UnitSystem.METRIC
      avoidHighways: false
      avoidTolls: false
    }, (data, status) ->
      listofdistances = data.rows[0].elements
      counter = 0

      $.each ReiDosFrangos, (i, val) ->
        # adds distances to objects
        distance = data.rows[0].elements[counter].distance.value
        val.distance = distance
        counter++

      ReiDosFrangos.sort (a, b) ->
        keyA = a.distance
        keyB = b.distance

        # Compare the distances
        if keyA < keyB
          return -1
        if keyA > keyB
          return 1
        0

      $('.bottombar').addClass 'visible'
      $('.bottombar .nearestcity').html ReiDosFrangos[0].city
      nearestDist = parseInt(ReiDosFrangos[0].distance / 1000)
      $('.bottombar .distance').html nearestDist + 'km'

      $ ->
        setTimeout ->
          $('.cityspot[data-id="' + ReiDosFrangos[0].id + '"]').append '<div
          class="nearest">
          <i class="fa fa-map-marker"></i><span class="hint">
          Estabelecimento mais próximo</span></div>'
          $('.cityspot[data-id="' + ReiDosFrangos[0].id + '"]').click()
        , 500

      sessionStorage.setItem 'nearestCity', ReiDosFrangos[0].city
      sessionStorage.setItem 'nearestDist', nearestDist
      sessionStorage.setItem 'nearestID', ReiDosFrangos[0].id

$ ->
  nearestCity = sessionStorage.getItem 'nearestCity'
  nearestDist = sessionStorage.getItem 'nearestDist'
  nearestID = sessionStorage.getItem 'nearestID'
  currentPos = sessionStorage.getItem 'currentPos'

  if nearestCity && nearestDist && nearestID && currentPos
    # distancias em local cache
    $('.bottombar .nearestcity').html nearestCity
    $('.bottombar .distance').html nearestDist + 'km'
    $('.bottombar').addClass 'visible'
  else
    # buscar distancias
    if (navigator.geolocation)
      navigator.geolocation.getCurrentPosition(calculateDistance)
    else
      console.log "Browser doesn't support geolocation."

  if $('section.stores')[0]
    if nearestCity && nearestDist && nearestID
      $ ->
        if nearestID isnt ''
          setTimeout ->
            $('.cityspot[data-id="' + nearestID + '"]').append '<div
            class="nearest">
            <i class="fa fa-map-marker"></i><span class="hint">
            Estabelecimento mais perto</span></div>'
            $('.cityspot[data-id="' + nearestID + '"]').click()
          , 500
# gets user location - end

# header slider index
sliderNumber = $('.elslider li').size()
$('.headerimage.active').css 'display', 'block'
sliderTiming = 15000 # 15 seconds
changetimer = '' # timer var

changeSlideImg = (newactive) ->
  $('.headerimage').eq(newactive).addClass 'active'
  $('.headerimage').eq(newactive).fadeIn()
  $('.headerimage').eq(newactive).siblings().removeClass 'active'
  $('.headerimage').eq(newactive).siblings().fadeOut()

  $('ul.currentspot li').eq(newactive).addClass 'active'
  $('ul.currentspot li').eq(newactive).siblings().removeClass 'active'

spotCounter = 0
while spotCounter < sliderNumber
  $('ul.currentspot').append '<li><i class="fa fa-circle"></i></li>'
  spotCounter++

$ ->
  if sliderNumber > 0
    # index page and/or has a slider
    $('ul.currentspot li').eq(0).addClass 'active'

    changetimer = window.setInterval ->
      currentactive = $('.headerimage.active').index()

      if currentactive + 1 < sliderNumber
        newactive = currentactive + 1
      else
        newactive = 0

      changeSlideImg(newactive)
    , sliderTiming

$('body').on 'click', 'ul.currentspot li', ->
  newvalue = $(@).index()

  changeSlideImg(newvalue)

  window.clearInterval changetimer # stops timer

  changetimer = window.setInterval ->
    # restarts timer
    currentactive = $('.headerimage.active').index()

    if currentactive + 1 < sliderNumber
      newactive = currentactive + 1
    else
      newactive = 0

    changeSlideImg(newactive)
  , sliderTiming


$('.arrows').click ->
  moveWhere = $(@).attr 'data-target'
  active = $('.currentspot li.active').index()
  totalNumSpots = $('.currentspot li').size()

  if moveWhere is 'left'
    if active > 0
      $('.currentspot li').eq(active - 1).click()
    else
      # se tiver no primeiro
      $('.currentspot li').eq(totalNumSpots - 1).click()


  if moveWhere is 'right'
    if active >= totalNumSpots - 1
      # se tiver no ultimo
      $('.currentspot li').eq(0).click()
    else
      $('.currentspot li').eq(active + 1).click()
# header slider index - end

# auto scroll to map
url = window.location.href

if $('section.stores')[0] && url.indexOf('#mapme') isnt -1
  $('html,body').animate {
    scrollTop: $('#mapme').offset().top
  }, 1000

  url = url.replace '#mapme', ''

  window.history.replaceState '', '', url
# auto scroll to map - end

# bottombar ux
if $('section.stores')[0]
  $('section.stores').css 'pointer', 'default'
  $('a.bottombar').click (e) ->
    e.preventDefault()
# bottombar ux - end

# current time
$ ->
  setInterval ->
    d = new Date()
    hours = d.getHours()
    mins = d.getMinutes()

    if hours < 10
      hours = '0' + hours
    if mins < 10
      mins = '0' + mins

    time = hours + ':' + mins
    $('span.currenttime').text time

    if hours >= 10 and hours < 22
      $('.effectivestatus').html 'Abertos'
    else
      $('.effectivestatus').html 'Fechados'
  , 1000
# current time - end

# encomendas populate list
if ReiDosFrangos.length > 0
  $.each ReiDosFrangos, (i, val) ->
    $('.encomendar select#where').append '<option value="' + val.id +
    '">' + val.nome + '</option>'

    if $('.orderdish')[0]
      $('.orderdish select#fromwhere').append '<option value="' + val.id +
      '">' + val.nome + '</option>'

  tele = ReiDosFrangos[0].tel

  if tele.indexOf('+') > -1
    telcontainsplus = true
  else
    telcontainsplus = false

  tele = tele.replace(/[^\dA-Z]/g, '').replace(/(.{3})/g, '$1 ').trim()

  if telcontainsplus
    tele = '+' + tele

  $('.encomendar .numberishere').html tele
  callablenum = ReiDosFrangos[0].tel.replace(/\s+/g, '')
  $('.encomendar .numberishere').attr 'href', 'tel:' + callablenum
  $('.encomendar .address').html ReiDosFrangos[0].address + ', ' +
  ReiDosFrangos[0].city

  if $('.orderdish')[0]
    $('.orderdish .telefone').html tele
    callablenumBuy = ReiDosFrangos[0].tel.replace(/\s+/g, '')
    $('.orderdish .telefone').attr 'href', 'tel:' + callablenumBuy
    $('.orderdish .address').html ReiDosFrangos[0].address + ', ' +
    ReiDosFrangos[0].city

  myLatlng = new (google.maps.LatLng)(ReiDosFrangos[0].lat, ReiDosFrangos[0].lng)
  firstStore = {lat: ReiDosFrangos[0].lat, lng: ReiDosFrangos[0].lng}
# encomendas populate list - end

# menu UX
fecharMenu = ->
  $('.thefullmenu').attr 'data-status', 'off'
  setTimeout ->
    $('.thefullmenu').css 'left', ''
  , 500

  $('.fullsizefilter').fadeOut()

  $('.hamburgermenu').children('.hamburger').removeClass 'active'

  selector = $('.thefullmenu .content, .thefullmenu .images')
  todo = ''

  selector.css '-webkit-transform', todo
  selector.css '-moz-transform', todo
  selector.css '-o-transform', todo
  selector.css 'transform', todo

  $('.bottombar').css 'min-width', ''
  $('.bottombar').css 'border-radius', ''

  $('body').css 'overflow', ''

$('.hamburgermenu').click ->
  status = $('.thefullmenu').attr 'data-status'

  if !$('.bottombar').hasClass 'visible'
    # fallback if cant find nearest location
    $('.thefullmenu').css 'height', '100%'

  if status is 'off'
    $('.thefullmenu').attr 'data-status', 'on'
    $('.thefullmenu').css 'left', '0'

    $('.fullsizefilter').fadeIn()

    $(@).children('.hamburger').addClass 'active'

    selector = $('.thefullmenu .content, .thefullmenu .images')
    todo = 'translate3d(0,0,0)'

    selector.css '-webkit-transform', todo
    selector.css '-moz-transform', todo
    selector.css '-o-transform', todo
    selector.css 'transform', todo

    if $(window).width() >= 600
      $('.bottombar').css 'min-width', '600px'
      $('.bottombar').css 'border-radius', '0'
    else
      $('.bottombar').css 'min-width', '100%'
      $('.bottombar').css 'border-radius', '0'

    $('body').css 'overflow', 'hidden'
  else
    fecharMenu()


$('.thefullmenu .content .food').hover (->
  $('.thefullmenu .images .food').addClass 'active'
), ->
  $('.thefullmenu .images .food').removeClass 'active'

$('.thefullmenu .content .business').hover (->
  $('.thefullmenu .images .business').addClass 'active'
), ->
  $('.thefullmenu .images .business').removeClass 'active'


$(document).mouseup (e) ->
  container = $('.thefullmenu')
  subcontainer = $('.hamburgermenu')
  status = $('.thefullmenu').attr 'data-status'

  if status is 'on'
    # only runs if menu is open
    if !container.is(e.target) and container.has(e.target).length == 0
      if !subcontainer.is(e.target) and subcontainer.has(e.target).length == 0
        fecharMenu()
    return

$(document).keyup (e) ->
  if e.keyCode == 27
    fecharMenu()
  else
  return
# menu UX - end

# Map stuff
createOrderMap = ->
  infowindow = new (google.maps.InfoWindow)
  bounds = new (google.maps.LatLngBounds)
  mapCanvas = $('.mapmicro')[0]
  mapOptionsMini =
      center: myLatlng
      zoom: 15
      mapTypeId: google.maps.MapTypeId.ROADMAP
      scrollwheel: false
      draggable: true
      disableDoubleClickZoom: false
      streetViewControl: false
      disableDefaultUI: true
      zoomControl: true
  map = undefined

  initGMap = ->
    i = undefined
    image = undefined
    marker = undefined
    map = new (google.maps.Map)(mapCanvas, mapOptionsMini)
    map.setOptions styles: styles
    image =
        url: '/assets/frontoffice/images/mapmarker.svg'
        size: new (google.maps.Size)(70, 70)
        anchor: new (google.maps.Point)(35, 35)

    i = 0
    marker = new (google.maps.Marker)(
      position: myLatlng
      map: map
      icon: image
      clickable: false
      title: 'Rei dos Frangos')

    resLat = firstStore.lat
    resLng = firstStore.lng

    if currentPos && currentPos isnt ''
      $('.mapmicro .gotodir').remove()
      $('.mapmicro').append '<div class="gotodir" data-lat="' + resLat + '"
      data-lng="' + resLng + '">Obter Direcções</div>'

  initGMap()

createBigMap = ->
  infowindow = new (google.maps.InfoWindow)
  bounds = new (google.maps.LatLngBounds)
  mapCanvas = $('.bigmap')[0]
  mapOptions =
      center: myLatlng
      zoom: 14
      mapTypeId: google.maps.MapTypeId.ROADMAP
      scrollwheel: false
      mapTypeControl: false
      streetViewControl: false
  bigmap = undefined

  initGMapBig = ->
    i = undefined
    image = undefined
    bigmarker = undefined
    bigmap = new (google.maps.Map)(mapCanvas, mapOptions)
    bigmap.setOptions styles: styles
    image =
        url: '/assets/frontoffice/images/mapmarker.svg'
        size: new (google.maps.Size)(70, 70)
        anchor: new (google.maps.Point)(35, 35)

    i = 0
    bigmarker = new (google.maps.Marker)(
      position: myLatlng
      map: bigmap
      icon: image
      clickable: false
      title: 'Rei dos Frangos')

  initGMapBig()

createHQMap = ->
  hqcoords = $('.micromaphq').attr 'data-gps'
  hqcoords = hqcoords.split(',')
  hqpos = new (google.maps.LatLng)(hqcoords[0], hqcoords[1])

  infowindow = new (google.maps.InfoWindow)
  mapCanvas = $('.micromaphq')[0]
  mapOptionsHQ =
      center: hqpos
      zoom: 15
      mapTypeId: google.maps.MapTypeId.ROADMAP
      scrollwheel: false
      draggable: true
      disableDoubleClickZoom: false
      streetViewControl: false
      disableDefaultUI: true
      zoomControl: true
  map = undefined

  initHQGMap = ->
    i = undefined
    image = undefined
    marker = undefined
    map = new (google.maps.Map)(mapCanvas, mapOptionsHQ)
    map.setOptions styles: styles
    image =
        url: '/assets/frontoffice/images/mapmarker.svg'
        size: new (google.maps.Size)(70, 70)
        anchor: new (google.maps.Point)(35, 35)

    marker = new (google.maps.Marker)(
      position: hqpos
      map: map
      icon: image
      clickable: false
      title: 'Rei dos Frangos')

    loadbutton = setInterval ->
      if currentPos && currentPos isnt ''
        coords = $('.micromaphq').attr 'data-gps'
        coords = coords.split(',')
        resLat = coords[0]
        resLng = coords[1]
        $('.micromaphq .gotodir').remove()
        $('.micromaphq').append '<div class="gotodir" data-lat="' + resLat + '"
        data-lng="' + resLng + '">Obter Direcções</div>'

        clearInterval loadbutton
    , 200

  initHQGMap()

if $('.bigmap')[0]
  bigmapzoom = 0
  createBigMap()

  if $(window).width() > 750
    bigmap.panBy(-190,0)
    bigmapzoom = bigmap.getZoom()

    bigmap.addListener 'zoom_changed', ->
      newzoom = bigmap.getZoom()
      oldzoom = bigmapzoom

      if newzoom > oldzoom
        bigmap.panBy(190,0)
      else
        bigmap.panBy(-95,0)

      bigmapzoom = bigmap.getZoom()

  if $(window).width() < 750
    bigmap.setOptions {draggable: false}


if $('.micromaphq')[0]
  createHQMap()
# Map stuff

# encomendas UX
fecharEncomendas = ->
  $('.encomendar').stop().attr 'data-status', 'off'
  $('.encomendar').stop().css 'right', ''

  $('.fullsizefilter').stop().fadeOut()

  $('.encomendas').stop().removeClass 'active'

  $('body').stop().css 'overflow', ''


$('.encomendas').click ->
  status = $('.encomendar').attr 'data-status'

  if status is 'off'
    $('.encomendar').stop().attr 'data-status', 'on'
    $('.encomendar').stop().css 'right', '0'

    $('.fullsizefilter').stop().fadeIn()

    $(@).stop().addClass 'active'

    $('body').stop().css 'overflow', 'hidden'

    mapstatus = $('.mapmicro').attr 'data-loaded'
    if $('.mapmicro')[0] and mapstatus isnt 'true'
      setTimeout ->
        $('.mapmicro').attr 'data-loaded', 'true'
        createOrderMap()
      , 500

  else
    fecharEncomendas()


$(document).mouseup (e) ->
  container = $('.encomendar')
  subcontainer = $('.encomendas')
  status = $('.encomendar').attr 'data-status'

  if status is 'on'
    # only runs if menu is open
    if !container.is(e.target) and container.has(e.target).length == 0
      if !subcontainer.is(e.target) and subcontainer.has(e.target).length == 0
        fecharEncomendas()
    return

$(document).keyup (e) ->
  if e.keyCode == 27
    fecharEncomendas()
  else
  return
# encomendas UX - end

# encomendas change restaurant info
$('select#where').change ->
  idtofetch = parseInt($('select#where option:selected').val())

  result = $.grep ReiDosFrangos, (e) ->
    e.id == idtofetch

  tele = result[0].tel

  if tele.indexOf('+') > -1
    telcontainsplus = true
  else
    telcontainsplus = false

  tele = tele.replace(/[^\dA-Z]/g, '').replace(/(.{3})/g, '$1 ').trim()

  if telcontainsplus
    tele = '+' + tele

  $('.encomendar .numberishere').html tele
  callablenum = result[0].tel.replace(/\s+/g, '')
  $('.encomendar .numberishere').attr 'href', 'tel:' + callablenum
  $('.encomendar .address').html result[0].address + ', ' +
  result[0].city

  resLat = parseFloat(result[0].lat)
  resLng = parseFloat(result[0].lng)

  LatLng = new (google.maps.LatLng)(resLat, resLng)

  if currentPos && currentPos isnt ''
    $('.mapmicro .gotodir').remove()
    $('.mapmicro').append '<div class="gotodir" data-lat="' + resLat + '"
    data-lng="' + resLng + '">Obter Direcções</div>'

  map.setCenter(LatLng)
  marker.setPosition(LatLng)


$('select#fromwhere').change ->
  idtofetch = parseInt($('select#fromwhere option:selected').val())

  result = $.grep ReiDosFrangos, (e) ->
    e.id == idtofetch

  tele = result[0].tel

  if tele.indexOf('+') > -1
    telcontainsplus = true
  else
    telcontainsplus = false

  tele = tele.replace(/[^\dA-Z]/g, '').replace(/(.{3})/g, '$1 ').trim()

  if telcontainsplus
    tele = '+' + tele

  $('.orderdish .telefone').html tele
  callablenum = result[0].tel.replace(/\s+/g, '')
  $('.orderdish .telefone').attr 'href', 'tel:' + callablenum
  $('.orderdish .address').html result[0].address + ', ' +
  result[0].city
# encomendas change restaurant info - end

# Header UX
if !$('.noscrollheader')[0]
  $(document).scroll ->
    scrolled = $(document).scrollTop()

    if scrolled <= 500 and $(window).width() > 600
      # header is 400px height, 100px for extra safety
      scrolledCSS = 'translate3d(0,' + scrolled/4 + 'px,0)'

      $('.headerimage').css '-webkit-transform', scrolledCSS
      $('.headerimage').css '-moz-transform', scrolledCSS
      $('.headerimage').css '-o-transform', scrolledCSS
      $('.headerimage').css 'transform', scrolledCSS
# Header UX - end

# Ementa hover
selector = subselector = otherSelector = ''
$('.menucontainer ul.themenu li').hover (->
  texttoshow = $(@).attr 'data-explain'
  selector = $(@).parent().parent().parent().parent()
  .siblings('.showdetails')
  otherSelector = $(@).parent().parent().parent().parent()
  .siblings('.whilenodetails')

  subselector = selector
  .children('.table')
  .children('.tablecell')
  .children('.menutextgoeshere')

  if typeof texttoshow isnt 'undefined'
    subselector.stop().html texttoshow
    selector.stop().fadeIn()
    otherSelector.stop().fadeOut()
), ->
  otherSelector.stop().fadeIn()
  selector.stop().fadeOut(400, ->
    subselector.stop().html ''
  )
# Ementa hover - end

# charts
chartsloaded = false
optionsCharts = {
  showTooltips: false
  percentageInnerCutout: 97
  animationEasing: 'easeInOutExpo'
  segmentShowStroke: false
}

thecolor = '#c90709'


if $('.infograph')[0]
  $('.dishstats .stat').each ->
    # animate charts
    ctx = $(@).find('canvas').get(0).getContext("2d")

    current = parseFloat($(@).find('.infograph').attr 'data-value')
    max = $(@).find('.infograph').attr 'data-max'
    diff = parseFloat(max - current)

    data = [
      {
        value: current
        color: thecolor
      },
      {
        value: diff
        color: 'transparent'
      }
    ]

    newChart = new Chart(ctx).Doughnut(data, optionsCharts)

    # animate numbers
    $(@).find('.valuetocount').animate {
      Counter: current
    },
      duration: 1500
      easing: 'swing'
      step: (now) ->
        $(@).text Math.ceil(now)
        return
    return
# charts - end

# gallery UX
closeGalleryModal = ->
  $('.gallerymodal img').animate {
    opacity: 0
  }, 400, ->
    $('.gallerymodal img').attr 'src', ''
    $('.gallerymodal').slideUp 400
    $('body').css 'overflow', ''

$('.imagegallery .image').click ->
  target = $(@).attr 'data-target'
  $('body').css 'overflow', 'hidden'

  windowheight = $(window).height()
  maximgheight = windowheight * 0.8 # 80% of window height
  $('.gallerymodal img').css 'max-height', maximgheight + 'px'

  $('.gallerymodal img').attr 'src', target
  $('.gallerymodal img').css 'opacity', 0

  $('.gallerymodal').slideDown(400, ->
    $('.gallerymodal img').animate {
      opacity: 1
    }, 400
  )

$('section.gallerymodal .closeme').click ->
  closeGalleryModal()

$(document).keyup (e) ->
  if e.keyCode == 27
    closeGalleryModal()
  else
  return
# gallery UX - end

# populate conctacts list
if $('.mapcontainer')[0]
  previouscity = ''
  RDF = ReiDosFrangos

  RDF.sort (a, b) ->
    keyA = a.city
    keyB = b.city

    # Compare the distances
    if keyA < keyB
      return -1
    if keyA > keyB
      return 1
    0

  counter = 0
  $.each RDF, (i, val) ->
    theclass = ''
    selector = $('.mapcontainer .spotscontainer')

    if val.city isnt previouscity
      selector.append '<div class="city">' + val.city + '</div>'
      previouscity = val.city

    if counter is 0
      theclass = 'active'

    tele = val.tel
    if tele.indexOf('+') > -1
      telcontainsplus = true
    else
      telcontainsplus = false

    tele = tele.replace(/[^\dA-Z]/g, '').replace(/(.{3})/g, '$1 ').trim()

    if telcontainsplus
      tele = '+' + tele

    call = val.tel.replace(/\s+/g, '')
    selector.append '<div class="cityspot ' + theclass + '"
    data-lat="' + val.lat + '" data-lng="' + val.lng + '"
    data-id="' + val.id + '">
    <div class="address">' + val.address + '</div>
    <a class="tel" href="tel:' + call + '">' + tele + '</a>
    </div>'

    counter++

  $('span.storecounter').text counter
# populate conctacts list - end

# contacts map UX
if $('.mapcontainer')[0]
  altura = $('.spotscontainer').outerHeight()
  $('.mapcontainer').css 'height', altura

  if altura >= $(window).height()
    $('.bigmap').css 'height', $(window).height() + 'px'
  else
    $('.bigmap').css 'height', altura

  mapoffset = $('.bigmap').offset().top

  $(document).scroll ->
    if $(window).width() > 750
      # only on desktops
      if altura >= $(window).height()
        $('.bigmap').css 'height', $(window).height() + 'px'
      else
        $('.bigmap').css 'height', altura

      scrolled = $(document).scrollTop()
      diff = scrolled - mapoffset

      if diff >= 0
        $('.bigmap').css 'position', 'fixed'
        $('.bigmap').css 'bottom', '0px'
      else if diff < 0
        $('.bigmap').css 'position', ''
        $('.bigmap').css 'bottom', ''

      # finished map height
      listheight = $('.spotscontainer').outerHeight()
      offset = $('.spotscontainer').offset().top
      difftwo = listheight + offset - (scrolled + $(window).height())

      if difftwo < 0
        $('.bigmap').css 'position', 'absolute'
        $('.bigmap').css 'bottom', '0px'


$('body').on 'click', 'section.stores .spotscontainer .cityspot', (e) ->
  if !e.originalEvent
    # .click() event - auto "shows" the spots
    if $(window).width() > 750
      $('html, body').animate {
        scrollTop: $(@).offset().top - 200
      }, 1000

  $(@).addClass 'active'
  $(@).siblings().removeClass 'active'

  lat = $(@).attr 'data-lat'
  lng = $(@).attr 'data-lng'

  if currentPos && currentPos isnt ''
    $('.bigmap .gotodir').remove()
    $('.bigmap').append '<div class="gotodir" data-lat="' + lat + '"
    data-lng="' + lng + '">Obter Direcções</div>'

  LatLng = new (google.maps.LatLng)(lat, lng)

  bigmap.setCenter(LatLng)

  if $(window).width() > 750
    bigmap.panBy(-190,0)

  if $(window).width() < 750
    $('html,body').animate {
      scrollTop: $('#mapme').offset().top - 70
    }, 500

  bigmarker.setPosition(LatLng)
# contacts map UX - end

# products slider
offset = 0
eachmargin = 20
productsize = 0

availClass = []

$('ul.productlist li').each ->
  thisCl = $(@).attr 'data-class'

  already = $.inArray(thisCl, availClass)

  if already is -1
    availClass.push thisCl

$('ul.groups li').each ->
  thissec = $(@).attr 'data-section'

  already = $.inArray(thissec, availClass)

  if already is -1
    $(@).remove()


resizeProducts = ->
  container = $('.productscontainer').innerWidth()
  windowwidth = $(window).width()

  if windowwidth > 1500
    productsize = container / 5
    productsize = productsize - (eachmargin*2)
    $('section.products ul.productlist li').css 'width', productsize + 'px'
  else if windowwidth <= 1500 and windowwidth > 1200
    productsize = container / 4
    productsize = productsize - (eachmargin*2)
    $('section.products ul.productlist li').css 'width', productsize + 'px'
  else if windowwidth <= 1200 and windowwidth > 750
    productsize = container / 3
    productsize = productsize - (eachmargin*2)
    $('section.products ul.productlist li').css 'width', productsize + 'px'
  else if windowwidth <= 750
    productsize = container / 2
    productsize = productsize - (eachmargin*2)
    $('section.products ul.productlist li').css 'width', productsize + 'px'

  # container width
  productnumber = $('ul.productlist li').size()
  containerwidth = $('.productscontainer').innerWidth()
  eachwidth = parseInt(productsize) + parseInt(eachmargin*2)
  finalwidth = eachwidth * productnumber
  finalwidth = finalwidth + productnumber


  $('.productlist').css 'width', finalwidth + 'px'

$ ->
  if $('.productscontainer')[0]
    resizeProducts()

$(window).resize ->
  if $('.productscontainer')[0]
    resizeProducts()

  # on resize, back to 0
  $('section.products ul.productlist').css '-webkit-transform',
  'translate3d(0,0,0)'
  $('section.products ul.productlist').css '-moz-transform',
  'translate3d(0,0,0)'
  $('section.products ul.productlist').css '-o-transform',
  'translate3d(0,0,0)'
  $('section.products ul.productlist').css 'transform',
  'translate3d(0,0,0)'

  $('section.products ul.groups li:first-child').addClass 'active'
  $('section.products ul.groups li:first-child').siblings().removeClass 'active'

  offset = 0
  # on resize, back to 0 - end


setOffset = (offset) ->
  $('section.products ul.productlist').css '-webkit-transform',
  'translate3d(-' + offset + 'px,0,0)'
  $('section.products ul.productlist').css '-moz-transform',
  'translate3d(-' + offset + 'px,0,0)'
  $('section.products ul.productlist').css '-o-transform',
  'translate3d(-' + offset + 'px,0,0)'
  $('section.products ul.productlist').css 'transform',
  'translate3d(-' + offset + 'px,0,0)'


$('section.products ul.groups li').click ->
  windowwidth = $(window).width()
  eachwidth = parseInt(productsize) + parseInt(eachmargin*2)
  $(@).addClass 'active'
  $(@).siblings().removeClass 'active'

  extra = windowwidth * 0.1 # 10 percent

  choice = $(@).attr 'data-section'
  offset = $('li[data-class="' + choice + '"]').first().index()

  offset = offset * parseInt(eachwidth)

  setOffset(offset)

$('section.products .slideleft').click ->
  eachwidth = parseInt(productsize) + parseInt(eachmargin*2)

  if offset - eachwidth >= 0
    offset = offset - eachwidth

    getVisible = offset / eachwidth

    section = $('ul.productlist li').eq(getVisible).attr 'data-class'

    selector = $('ul.groups li[data-section="' + section + '"]')
    selector.addClass 'active'
    selector.siblings().removeClass 'active'

    setOffset(offset)

$('section.products .slideright').click ->
  eachwidth = parseInt(productsize) + parseInt(eachmargin*2)
  listwidth = parseInt($('ul.productlist').css 'width')

  if offset + eachwidth <= listwidth - eachwidth
    offset = offset + eachwidth

    getVisible = offset / eachwidth

    section = $('ul.productlist li').eq(getVisible).attr 'data-class'

    selector = $('ul.groups li[data-section="' + section + '"]')
    selector.addClass 'active'
    selector.siblings().removeClass 'active'

    setOffset(offset)
# products slider - end

# todaysmenu index FX
if $('section.ementadodia')[0]
  where = ['topleft', 'topright', 'bottomleft', 'bottomright']

  i = 0
  while i < 10
    randWhere = where[Math.floor(Math.random() * where.length)]
    randtop = parseInt(Math.random() * 100) - 50
    randleft = parseInt(Math.random() * 100) - 50

    triangleMultiplier = 1500 # 10 times
    height = parseInt(Math.random() * triangleMultiplier)
    width = parseInt(Math.random() * triangleMultiplier)

    switch randWhere
      when 'topleft'
        size = 'border-width: ' + height + 'px ' + width + 'px 0 0'
      when 'topright'
        size = 'border-width: 0 ' + height + 'px ' + width + 'px 0'
      when 'bottomright'
        size = 'border-width: 0 0 ' + height + 'px ' + width + 'px'
      when 'bottomleft'
        size = 'border-width: ' + height + 'px 0 0 ' + width + 'px'

    $('section.ementadodia .background .trianglescontainer').append '<div
    class="triangle ' + randWhere + '" style="top: ' + randtop + '%;
    left: ' + randleft + '%; ' + size + ';"></div>'
    i++
# todaysmenu index FX - end

# newslleter big inputs ux
nexttext = $('section.newsletterbig .next').text()

$('section.newsletterbig .next').click ->
  offset = $('.inputscontainer').attr 'data-visible'

  if offset < 3
    offset++

    $('.inputscontainer').attr 'data-visible', offset
    $('.currentinput span.counter').text offset

    switch offset
      when 2
        $('section.newsletterbig .prev').fadeIn()
        $('section.newsletterbig .next').text nexttext
        $('.inputscontainer').css '-webkit-transform', 'translate3d(-320px,0,0)'
        $('.inputscontainer').css '-moz-transform', 'translate3d(-320px,0,0)'
        $('.inputscontainer').css '-o-transform', 'translate3d(-320px,0,0)'
        $('.inputscontainer').css 'transform', 'translate3d(-320px,0,0)'
        $('section.newsletterbig .next').show()
        $('section.newsletterbig input.bigformsubmit').hide()
      when 3
        $('section.newsletterbig .prev').fadeIn()
        $('.inputscontainer').css '-webkit-transform', 'translate3d(-640px,0,0)'
        $('.inputscontainer').css '-moz-transform', 'translate3d(-640px,0,0)'
        $('.inputscontainer').css '-o-transform', 'translate3d(-640px,0,0)'
        $('.inputscontainer').css 'transform', 'translate3d(-640px,0,0)'
        $('section.newsletterbig .next').hide()
        $('section.newsletterbig input.bigformsubmit').show()

$('section.newsletterbig .prev').click ->
  offset = $('.inputscontainer').attr 'data-visible'

  if offset > 1
    offset--

    $('.inputscontainer').attr 'data-visible', offset
    $('.currentinput span.counter').text offset

    switch offset
      when 1
        $('section.newsletterbig .prev').fadeOut()
        $('.inputscontainer').css '-webkit-transform', 'translate3d(0,0,0)'
        $('.inputscontainer').css '-moz-transform', 'translate3d(0,0,0)'
        $('.inputscontainer').css '-o-transform', 'translate3d(0,0,0)'
        $('.inputscontainer').css 'transform', 'translate3d(0,0,0)'
      when 2
        $('section.newsletterbig .prev').fadeIn()
        $('.inputscontainer').css '-webkit-transform', 'translate3d(-320px,0,0)'
        $('.inputscontainer').css '-moz-transform', 'translate3d(-320px,0,0)'
        $('.inputscontainer').css '-o-transform', 'translate3d(-320px,0,0)'
        $('.inputscontainer').css 'transform', 'translate3d(-320px,0,0)'
        $('section.newsletterbig .next').show()
        $('section.newsletterbig input.bigformsubmit').hide()


$(document).on 'keypress', 'form.bigform', (e) ->
  if e.keyCode is 13
    # ENTER pressed
    e.preventDefault()

    offset = $('.inputscontainer').attr 'data-visible'
    offsetInt = parseInt(offset)

    if offset is '3'
      $('form.bigform').submit()
    else
      $('section.newsletterbig .next').click()
      $('.inputscontainer input').eq(offsetInt).select()

$(document).on 'keydown', 'form.bigform', (e) ->
  if e.keyCode is 9
    # TAB pressed
    e.preventDefault()

    offset = $('.inputscontainer').attr 'data-visible'
    offsetInt = parseInt(offset)

    if offset isnt '3'
      $('section.newsletterbig .next').click()
      $('.inputscontainer input').eq(offsetInt).select()
# newslleter big inputs ux - end

# dish categories dishlist
firstCateg = true
$('section.dishlist .category .what').each ->
  if firstCateg
    firstCateg = false
    $('ul.gotocateg').append '<li data-categtoview="all" class="active"><a href="#">Todos</a></li>'

  name = $(@).text()
  name = name.toLowerCase()
  id = $(@).parent().attr 'data-categ'

  $(@).parent().attr 'id', name

  $('ul.gotocateg').append '<li data-categtoview="' +
  id + '"><a href="#' + name + '">' +
  name + '</a></li>'


showCateg = (categid) ->
  $('.category:not([data-categ="' + categid + '"])').hide()
  $('.categoryDishes:not([data-categ="' + categid + '"])').hide()

  $('.category[data-categ="' + categid + '"]').show()
  $('.categoryDishes[data-categ="' + categid + '"]').show()


$('body').on 'click', 'ul.gotocateg li a', (e) ->
  e.preventDefault()

  categid = $(@).parent().attr 'data-categtoview'
  status = $(@).parent().hasClass 'active'
  selector = $('.categoryDishes[data-categ="' + categid + '"]')
  button = $(@).parent()

  button.addClass 'active'
  button.siblings().removeClass 'active'


  if categid isnt 'all'
    # mostrar so categoria
    showCateg(categid)
  else if categid is 'all'
    # mostrar tudo
    $('.category').show()
    $('.categoryDishes').show()

# dish categories dishlist - end

# label contacts UX
if $('section.contacts')[0]
  $('section.contacts input').focus ->
    $(@).siblings('label').addClass 'backtoplace'

  $('section.contacts input').focusout ->
    valor = $(@).val()

    if valor is ''
      $(@).siblings('label').removeClass 'backtoplace'

  $('section.contacts textarea').focus ->
    $(@).siblings('label').addClass 'backtoplace'

  $('section.contacts textarea').focusout ->
    valor = $(@).val()

    if valor is ''
      $(@).siblings('label').removeClass 'backtoplace'
# label contacts UX - end

# nearest est
$('body').on 'mouseenter', '.cityspot .nearest i.fa', ->
  $(@).siblings().addClass 'visible'

$('body').on 'mouseleave', '.cityspot .nearest i.fa', ->
  $(@).siblings().removeClass 'visible'
# nearest est - end

# social share
$('.socialshare a.twitter').click (e) ->
  e.preventDefault()
  width = 575
  height = 400
  left = ($(window).width() - width) / 2
  top = ($(window).height() - height) / 2
  url = @href
  opts = 'status=1' +
  ',width=' + width +
  ',height=' + height +
  ',top=' + top +
  ',left=' + left

  window.open url, 'twitter', opts

$('.socialshare a.facebook').click (e) ->
  e.preventDefault()
  currentURL = window.location.href
  FB.ui {
    method: 'share'
    href: currentURL
  }, (response) ->
# social share - end

# curtom FB feed
$ ->
  checkTimeline = setInterval ->
    if fbloaded
      pageAccessToken = '1659266797684694|J9mTeUAjp186w55XGrPQ_9UNqBw'
      url = '/201927525852/posts'
      options = '?fields=link,full_picture,message,created_time'

      FB.api url + options, access_token: pageAccessToken, (response) ->
        if response and !response.error
          info = response.data

          $('.titlefb').fadeIn()

          postscounter = 0

          info.forEach (item) ->
            if postscounter < 5
              date = new Date(item.created_time)
              date = date.toLocaleDateString('pt-PT')
              message = item.message

              if message.length > 250
                # meter um fade nas mensagens muito grandes
                message += '<div class="hidderofmsg"></div>'

              if typeof item.link is 'undefined'
                if item.full_picture isnt '' and
                typeof item.full_picture isnt 'undefined'
                  # se tiver imagem
                  $('.fbfeed ul').append '<li>
                  <div class="imgcontain"><img class="picture" src="' +
                  item.full_picture + '"></div><div class="fbcontain">
                  <div class="table"><div class="tablecell">
                  <div class="message">' +
                  message + '</div><div class="date">' +
                  date + '</div></div></div></div></li>'
                else
                  # se NÃO tiver imagem
                  $('.fbfeed ul').append '<li>
                  <div class="fbcontain visible">
                  <div class="table"><div class="tablecell">
                  <div class="message">' +
                  message + '</div><div class="date">' +
                  date + '</div></div></div></div></li>'
              else
                if item.full_picture isnt '' and
                typeof item.full_picture isnt 'undefined'
                  # se tiver imagem
                  $('.fbfeed ul').append '<li>
                  <a href="' + item.link + '" target="_blank">
                  <div class="imgcontain"><img class="picture" src="' +
                  item.full_picture + '"></div><div class="fbcontain">
                  <div class="table"><div class="tablecell">
                  <div class="message">' +
                  message + '</div><div class="date">' +
                  date + '</div></div></div></div></a></li>'
                else
                  # se NÃO tiver imagem
                  $('.fbfeed ul').append '<li>
                  <a href="' + item.link + '" target="_blank">
                  <div class="fbcontain visible">
                  <div class="table"><div class="tablecell">
                  <div class="message">' +
                  message + '</div><div class="date">' +
                  date + '</div></div></div></div></a></li>'

            postscounter++
        else
          console.log response.error
        return

      clearInterval checkTimeline
  , 100
# curtom FB feed - end

# hints
hintsLoaded = sessionStorage.getItem 'hintsseen'

if hintsLoaded
  # ja carregou os hints, remover as divs
  $('.hintencomendas, .hintencomendas').remove()
else
  # ainda não carregou os hints, mostrar
  setTimeout ->
    $('.hintencomendas, .hintencomendas').fadeIn()

    setTimeout ->
      $('.hintencomendas, .hintencomendas').fadeOut()
    , 7500
  , 1000
  sessionStorage.setItem 'hintsseen', true
# hints - end

# pretty phone link (contacts)
if $('section.contacts')[0]
  tel = $('.telefonehere a').text()

  if tel.indexOf('+') > -1
    telcontainsplus = true
  else
    telcontainsplus = false

  tel = tel.replace(/[^\dA-Z]/g, '').replace(/(.{3})/g, '$1 ').trim()

  if telcontainsplus
    tel = '+' + tel

  $('.telefonehere a').text tel
# pretty phone link (contacts) - end

# campaigns modal
$('section.header .headerimage .slogan a').click (e) ->
  e.preventDefault()

  img = $(@).attr 'data-camp-img'
  name = $(@).attr 'data-camp-name'
  desc = $(@).attr 'data-camp-desc'

  status = $('.campaignmodal').css 'display'

  if status isnt 'block'
    # fechada. abrir
    $('.campaignmodal .name').text name
    $('.campaignmodal .phrase').text desc
    $('.campaignmodal .image img').attr 'src', img
    $('.campaignmodal').fadeIn(400, ->
      $('.stuffgoeshere').fadeIn(400)
    )

$('.campaignmodal .thecrosse').click ->
  # aberta. Fechar
  $('.stuffgoeshere').fadeOut(400, ->
    $('.campaignmodal').fadeOut(400, ->
      $('.campaignmodal .name, .campaignmodal .phrase').text ''
      $('.campaignmodal .image img').attr 'src', ''
    )
  )

$(document).keyup (e) ->
  if e.keyCode == 27
    $('.stuffgoeshere').fadeOut(400, ->
      $('.campaignmodal').fadeOut(400, ->
        $('.campaignmodal .name, .campaignmodal .phrase').text ''
        $('.campaignmodal .image img').attr 'src', ''
      )
    )
# campaigns modal - end

# notifications
if $('.notif')[0]
  setTimeout ->
    $('.notif').slideDown()
  , 500

  setTimeout ->
    $('.notif').slideUp()
  , 10000

  $('.notif .closememodal').click ->
    $('.notif').slideUp()
# notifications - end

# close me routes to rdf
$('.routemetordf .closeme').click ->
  $('.routemetordf').fadeOut(400, ->
    $('.routemetordf .directionlist').empty()
  )
  $('body').removeClass 'dirMapOpen'


$(document).keyup (e) ->
  if e.keyCode == 27
    $('.routemetordf').fadeOut(400, ->
      $('.routemetordf .directionlist').empty()
    )
    $('body').removeClass 'dirMapOpen'
# close me routes to rdf - end

# Just for lolz
$ ->
  console.log '%cRei dos Frangos',
  'color:#fff;background:#333;
  text-transform: uppercase; font-weight: 700; padding: 3px 10px;'

  console.log '%cwebsite by amplified creations',
  'color:#f16622;font-family: "Ubuntu Mono";'

  console.log '%c> https://amplifiedcreations.com',
  'color:#333; font-family: "Ubuntu Mono"; font-style: italic;'

  console.log '%c-------------', 'color:#eee;'
# Just for lolz - END
